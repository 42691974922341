import { AppContainer } from './components/AppContainer/AppContainer'
import storeCarouselOnly from './store/storeCarouselOnly'
import { CarouselContainer } from './components/CarouselContainer/CarouselContainer'
import { Instances } from './components/Instances/Instances'
import { css } from 'styled-components'
import { Header } from './components/_header/Header/Header'
import { createRoot, hydrateRoot } from 'react-dom/client'

const AppJSX = () => (
  <AppContainer store={storeCarouselOnly} showErrorBoundary={false}>
    <Header />
     <Instances/>
  </AppContainer>
)

if (typeof window.location !== 'undefined') {
  if (document.getElementById('root')?.innerHTML?.length) {
    hydrateRoot(
      document.getElementById('root'),
      <AppJSX/>
    )
  } else {
    const el = document.getElementById('root');
    if (el){
      createRoot(el)?.render(<AppJSX/>)
    }
  }
}

if (document.getElementById('reactCarousel')) {
  createRoot(
    document.getElementById('reactCarousel')
  ).render(<AppContainer
    store={storeCarouselOnly}
  >
    <CarouselContainer
      gqlType='homepage'
      index={0}
      containerDivStyles={css`
          width: 100%;
          @media (min-width: 1200px) {
            padding: 0 9.5rem;
          }
        `}
      parentDivStyles={css`
          width: 100%;
          margin-bottom: 0;
        `}
      carouselContainerStyles={css`
          justify-content: space-evenly;
          margin-bottom: 3rem; 
          width: 100%;
        `}
    />
  </AppContainer>)
}

if (document.getElementById('reactCarousel2')) {
  createRoot(
    document.getElementById('reactCarousel2'),
  ).render(<AppContainer
    store={storeCarouselOnly}
  >
    <CarouselContainer
      gqlType='homepage'
      index={1}
      containerDivStyles={css`
          width: 100%;
          @media (min-width: 1200px) {
            padding: 0 9.5rem;
          }
        `}
      parentDivStyles={css`
          width: 100%;
          margin-bottom: 0;
        `}
      carouselContainerStyles={css`
          justify-content: space-evenly;
          margin-bottom: 3rem; 
          width: 100%;
        `}
    />
  </AppContainer>)
}